module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-84105213-11","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../plugins/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"hotel-griffintown","defaultLanguage":"fr-ca","langs":["en-us","fr-ca"],"pages":[{"type":"apartment","match":"/apartments/:uid","previewPath":"/apartment","component":"/opt/build/repo/src/templates/apartment.js"},{"type":"offer","match":"/offers/:uid","previewPath":"/offer","component":"/opt/build/repo/src/templates/offer.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/src/intl","languages":["fr-ca","en-us"],"defaultLanguage":"fr-ca","redirect":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-N6X4QJ9","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby-custom"}},"dataLayerName":"dataLayer_gatsby","routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
