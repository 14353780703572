// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-apartments-js": () => import("./../../../src/pages/apartments.js" /* webpackChunkName: "component---src-pages-apartments-js" */),
  "component---src-pages-bar-js": () => import("./../../../src/pages/bar.js" /* webpackChunkName: "component---src-pages-bar-js" */),
  "component---src-pages-concept-js": () => import("./../../../src/pages/concept.js" /* webpackChunkName: "component---src-pages-concept-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-covid-js": () => import("./../../../src/pages/covid.js" /* webpackChunkName: "component---src-pages-covid-js" */),
  "component---src-pages-explore-montreal-js": () => import("./../../../src/pages/explore-montreal.js" /* webpackChunkName: "component---src-pages-explore-montreal-js" */),
  "component---src-pages-gift-card-js": () => import("./../../../src/pages/gift-card.js" /* webpackChunkName: "component---src-pages-gift-card-js" */),
  "component---src-pages-gift-card-thanks-js": () => import("./../../../src/pages/gift-card-thanks.js" /* webpackChunkName: "component---src-pages-gift-card-thanks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offers-js": () => import("./../../../src/pages/offers.js" /* webpackChunkName: "component---src-pages-offers-js" */),
  "component---src-pages-policies-js": () => import("./../../../src/pages/policies.js" /* webpackChunkName: "component---src-pages-policies-js" */),
  "component---src-pages-press-review-js": () => import("./../../../src/pages/press-review.js" /* webpackChunkName: "component---src-pages-press-review-js" */),
  "component---src-templates-apartment-js": () => import("./../../../src/templates/apartment.js" /* webpackChunkName: "component---src-templates-apartment-js" */),
  "component---src-templates-offer-js": () => import("./../../../src/templates/offer.js" /* webpackChunkName: "component---src-templates-offer-js" */)
}

